import React from 'react'
import Policy from './data-addendum.styles'

const DataAddendum = () => {
  return (
    <Policy>
      <Policy.content>
        <Policy.title>Creativ.eMail Data Processing Addendum</Policy.title>
        <Policy.paragraph>
          This data processing agreement forms part of the <Policy.inlineLink to={'/api-term'}>Creativ.eMail API
          Terms and Conditions</Policy.inlineLink> (the “API Terms and Conditions”) and is between
          Constant Contact, Inc. at 1601 Trapelo Road, Waltham, Massachusetts
          02451, United States (“<Policy.bold>Creativ.eMail</Policy.bold>” or
          the “<Policy.bold>Data Processor</Policy.bold>”) and the Creativ.eMail
          developer agreeing to the API Terms & Conditions (the “
          <Policy.bold>Data Controller</Policy.bold>”) and incorporates the
          terms and conditions set out in the Schedules hereto (the “
          <Policy.bold>Addendum</Policy.bold>”).
        </Policy.paragraph>
        <Policy.paragraph>
          The Parties to this Addendum hereby agree to be bound by the following
          terms and conditions, including the attached Schedules as applicable
          with effect from the effective date of the API Terms and Conditions
          (the “<Policy.bold>Effective Date</Policy.bold>”).
        </Policy.paragraph>
        <Policy.list>
          <Policy.titleListChild>
            Application of the terms in this Addendum
          </Policy.titleListChild>
          <Policy.list isAlpha>
            <Policy.listChild>
              <Policy.paragraph>
                Pursuant to the API Terms and Conditions, the Developer is
                permitted to use Creativ.eMail’s API and provide Offerings to
                Users by leveraging the Creativ.eMail Products.
              </Policy.paragraph>
            </Policy.listChild>
            <Policy.listChild>
              <Policy.paragraph>
                In the event the Offering provided to Users by the Developer
                involves:
              </Policy.paragraph>
              <Policy.list>
                <Policy.listChild>
                  Creativ.eMail’s Processing Personal Data on behalf of the
                  Developer, the terms set out in Schedule 1 shall apply; and
                </Policy.listChild>
                <Policy.listChild>
                  the sharing of Personal Data between the Developer and
                  Creativ.eMail where each party determines the purpose and
                  means of Processing of such Personal Data independently, the
                  terms set out in Schedule 2 shall apply.
                </Policy.listChild>
              </Policy.list>
            </Policy.listChild>
            <Policy.listChild>
              In the event of any conflict or discrepancy between the terms of
              the API Terms and Conditions and this Addendum, the terms of this
              Addendum shall prevail, to the extent of the conflict.
            </Policy.listChild>
          </Policy.list>
          <Policy.titleListChild>Definitions</Policy.titleListChild>
          <Policy.paragraph>
            For the purposes of this Addendum, all terms defined in the API
            Terms and Conditions have the same meaning in this Addendum except
            for the following expressions, which bear the following meanings
            unless the context otherwise requires:
          </Policy.paragraph>
          <Policy.paragraph>
            “<Policy.bold>Data Protection Laws</Policy.bold>” means all
            applicable laws relating to data protection and privacy, including
            the General Data Protection Regulation 2016/679 (“
            <Policy.bold>GDPR</Policy.bold>”) and the e-Privacy Directive
            2002/58/EC (the “<Policy.bold>e-Privacy Directive</Policy.bold>”)
            and any law, statute, declaration, decree, directive, legislative
            enactment, order, ordinance, regulation, rule or other binding
            instrument implementing or supplementing the GDPR and the e-Privacy
            Directive (in each case as amended, consolidated, re-enacted or
            replaced from time to time);
          </Policy.paragraph>
          <Policy.paragraph>
            “<Policy.bold>Processor Model Clauses</Policy.bold>” means the
            standard contractual clauses for the transfer of Personal Data to
            Processors established in Third Countries set out in the Commission
            Decision of 5 February 2010 (C(2010) 593), as amended by EU
            Commission Implementing Decision 2016/2297 of 16 December 2016;
          </Policy.paragraph>
          <Policy.paragraph>
            “<Policy.bold>Controller Model Clauses</Policy.bold>” means the
            standard contractual clauses for the transfer of Personal Data to
            controllers established in third countries set out in the European
            Commission Decision 2004/915/EC as set out in Annex 1 and any
            amendments or replacements to such decision;
          </Policy.paragraph>
          <Policy.paragraph>
            “
            <Policy.bold>
              Personal Data”, “ Process”, “Processed”, “Processing”,
              “Supervisory Authority”, “Personal Data Breach”
            </Policy.bold>{' '}
            or <Policy.bold>“Data Subject”</Policy.bold> have the meaning given
            in the GDPR;
          </Policy.paragraph>
          <Policy.paragraph>
            “<Policy.bold>Purpose</Policy.bold>” means enabling the Developer to
            provide Offerings to Users by leveraging the Creativ.eMail Products
            in accordance with the API Terms and Conditions; and
          </Policy.paragraph>
          <Policy.paragraph>
            “<Policy.bold>Third Countries</Policy.bold>” means all countries
            outside of the scope of the data protection laws of the European
            Economic Area (“<Policy.bold>EEA</Policy.bold>”), excluding
            countries approved as providing adequate protection for Personal
            Data by the European Commission from time to time.
          </Policy.paragraph>
          <Policy.titleListChild>Indemnity and Liability</Policy.titleListChild>
          <Policy.paragraph>
            The Developer shall indemnify and hold harmless Creativ.eMail on
            demand from and against all claims, liabilities, costs, expenses,
            loss or damage (including consequential losses, loss of profit and
            loss of reputation and all interest, penalties and legal and other
            professional costs and expenses) incurred by Creativ.eMail arising
            directly or indirectly from a breach of this Addendum or any Data
            Protection Laws. For the avoidance of doubt, the indemnity and
            limitation of liability provisions set out in the API Terms and
            Conditions apply to this Addendum.
          </Policy.paragraph>
          <Policy.titleListChild>Law and Jurisdiction</Policy.titleListChild>
          <Policy.paragraph>
            This Addendum and any dispute or claim (including non-contractual
            disputes or claims) arising out of or in connection with it or its
            subject matter or formation shall be governed by and construed in
            all respects in accordance with the laws of the Commonwealth of
            Massachusetts and each party hereby submits to the jurisdiction of
            the federal or state courts located in Boston, Massachusetts.
          </Policy.paragraph>
        </Policy.list>
        <Policy.subtitle>Schedule 1</Policy.subtitle>
        <Policy.subtitle>
          TERMS FOR PROCESSING PERSONAL DATA ON DEVELOPERS BEHALF
        </Policy.subtitle>
        <Policy.list>
          <Policy.titleListChild>Details of Processing</Policy.titleListChild>
          <Policy.paragraph>
            1.1 Creativ.eMail shall Process the Personal Data on behalf of the
            Developer for the Purpose, which will include the following
            processing activities: storage, retrieval, use, disclosure, erasure,
            destruction and access of the Personal Data.
          </Policy.paragraph>
          <Policy.paragraph>
            1.2 The duration of the Processing shall be for the term of the API
            Terms and Conditions.
          </Policy.paragraph>
          <Policy.paragraph>
            1.3 The category of Data Subject is the Users and the Personal Data
            Processed by Creativ.eMail includes and shall be limited to the
            following categories of data: (i) identification and contact
            information (such as name, email address, address, title and contact
            details) of Users’ customers and other contacts; (ii) information
            gathered in connection with enabling the Developer to provide
            Offerings to Users relating to Users’ contacts, including (a)
            analytics and information about marketing emails sent by
            Creativ.eMail on behalf of User (including open rates and similar
            usage data) and (b) device, browser and information related to
            Users’ contacts identified through use of the Offerings.
          </Policy.paragraph>
          <Policy.paragraph>
            1.4 The Personal Data Processed by Creativ.eMail may contain special
            categories of Personal Data.
          </Policy.paragraph>
          <Policy.titleListChild>
            Creativ.eMail’s Obligations
          </Policy.titleListChild>
          <Policy.paragraph>
            2.1 Creativ.eMail shall only Process Personal Data on behalf of
            Developer in accordance with the instructions of the Developer. The
            parties agree that this Addendum together with the API Terms and
            Conditions comprise the Developer’s instructions. If Creativ.eMail
            cannot provide such compliance for whatever reason (including if the
            instruction violates Data Protection Laws), it agrees to inform
            Developer of its inability to comply as soon as reasonably
            practicable.
          </Policy.paragraph>
          <Policy.paragraph>
            2.2 Creativ.eMail shall ensure that its personnel who are authorized
            to Process the Personal Data have committed themselves to
            confidentiality or are under an appropriate statutory obligation of
            confidentiality.
          </Policy.paragraph>
          <Policy.paragraph>
            2.3 Creativ.eMail shall implement and hold in force for the term of
            this Addendum specific technical and organizational security
            measures as required by the GDPR which are further detailed at{' '}
            <Policy.inlineLink
              href="https://www.constantcontact.com/legal/security"
              target="_blank"
            >
              https://www.constantcontact.com/legal/security
            </Policy.inlineLink>{' '}
            (the “Security Policy”).
          </Policy.paragraph>
          <Policy.paragraph>
            2.4 Creativ.eMail shall notify Developer promptly upon receipt by
            Creativ.eMail of a request from a User seeking to exercise any of
            their rights under Data Protection Laws. Taking into account the
            nature of the Processing, Creativ.eMail shall, at Developer’s
            expense, assist Developer by appropriate technical and
            organizational measures, for the fulfilment of Developer’s
            obligation to respond to requests by Data Subjects to exercise their
            rights under Chapter III of the GDPR (including the right to
            transparency and information, the data subject access right, the
            right to rectification and erasure, the right to the restriction of
            processing, the right to data portability and the right to object to
            processing).
          </Policy.paragraph>
          <Policy.paragraph>
            2.5 Taking into account the nature of the Processing under the API
            Terms and Conditions and the information available to Creativ.eMail,
            Creativ.eMail shall, insofar as possible and at Developer’s expense,
            assist Developer in carrying out its obligations under Articles 32
            to 36 of the GDPR and any other Data Protection Laws with respect to
            security, breach notifications, impact assessments and consultations
            with Supervisory Authorities. Creativ.eMail shall promptly notify
            Developer about any Personal Data Breach of the Personal Data
            Processed on behalf of the Developer.
          </Policy.paragraph>
          <Policy.paragraph>
            2.6 Upon termination of the Processing of Personal Data on behalf of
            the Developer by Creativ.eMail and at the choice of Developer,
            Creativ.eMail shall either (i) delete all Personal Data; or (ii)
            return all Personal Data to the Developer and delete existing copies
            unless applicable law requires storage of the Personal Data.
          </Policy.paragraph>
          <Policy.paragraph>
            2.7 Creativ.eMail shall upon written request from Developer from
            time to time provide Developer with all information necessary to
            demonstrate compliance with the obligations laid down in this
            Addendum. Creativ.eMail shall permit Developer or a third party
            authorized by it and which is not a competitor of Creativ.eMail, to
            carry out audits and inspections of the processing of Personal Data
            by Creativ.eMail, on reasonable notice in normal business hours.
            Creativ.eMail may require a third party auditor to enter into a
            confidentiality agreement before permitting it to carry out an audit
            or inspection. Unless such audit or inspection has been necessitated
            by a material breach by Creativ.eMail of the terms of this Addendum,
            such audits and inspections shall be carried out at Developer’s
            expense.
          </Policy.paragraph>
          <Policy.paragraph>
            2.8 Developer acknowledges and agrees that Creativ.eMail may, or may
            appoint an affiliate or third party subcontractor to, Process the
            Developer’s Personal Data in a Third Country, provided that it
            ensures that such Processing takes place in accordance with the
            requirements of Data Protection Laws. Developer hereby consents to
            Creativ.eMail’s access to Personal Data from the United States for
            the Purpose.
          </Policy.paragraph>
          <Policy.paragraph>
            2.9 Developer acknowledges and agrees that Creativ.eMail relies
            solely on Developer for direction as to the extent to which
            Creativ.eMail is entitled to access, use and process Personal Data.
            Consequently, Creativ.eMail is not liable for any claim brought by
            Developer or a data subject arising from any action or omission by
            Creativ.eMail to the extent that such action or omission resulted
            from Developer’s instructions.
          </Policy.paragraph>
          <Policy.titleListChild>Developer’s Obligations</Policy.titleListChild>
          <Policy.paragraph>
            3.1 Developer warrants that it has complied and continues to comply
            with the Data Protection Laws, in particular that it has obtained
            any necessary consents or given any necessary notices, and otherwise
            has a legitimate ground to disclose the data to Creativ.eMail and
            enable the Processing of the Personal Data by the Creativ.eMail as
            set out in this Addendum and as envisaged by the API Terms and
            Conditions.
          </Policy.paragraph>
          <Policy.titleListChild>Sub-Contracting</Policy.titleListChild>
          <Policy.paragraph>
            4.1 Developer hereby consents to the use by Creativ.eMail of the
            Subcontractors set out in the{' '}
            <Policy.inlineLink
              href="https://www.constantcontact.com/legal/privacy-notice"
              target="_blank"
            >
              Privacy Notice
            </Policy.inlineLink>
            . If Creativ.eMail appoints a new Subcontractor to Process Personal
            Data, it shall provide Developer with twenty (20) business days’
            prior written notice, during which Developer can object to the
            appointment. If Developer does not object, Creativ.eMail may proceed
            with the appointment. Creativ.eMail ensures that it has a written
            agreement in place with all Subcontractors which contains
            obligations on the Subcontractor which are no less onerous on the
            relevant Subcontractor than the obligations on Creativ.eMail under
            this Addendum.
          </Policy.paragraph>
          <Policy.titleListChild>
            Transfers outside the EEA
          </Policy.titleListChild>
          <Policy.paragraph>
            5.1 The Developer acknowledges and agrees that the Creativ.eMail may
            Process the Personal Data in the United States in accordance with
            Constant Contact, Inc.’s Privacy Shield certification which can be
            accessed here. In the event the Privacy Shield is invalidated, where
            Creativ.eMail processes, accesses, and/or stores Personal Data in
            any Third Country and the Developer transfers Personal Data from the
            European Economic Area, Creativ.eMail shall comply with the data
            importer’s obligations set out in the Processor Model Clauses, which
            are hereby incorporated into and form part of this Addendum and
            Developer shall comply with the data exporter’s obligations. The
            processing details set out at Clause 4 of this Schedule 1 of this
            Addendum shall apply for the purposes of Appendix 1 of the Processor
            Model Clauses and the terms of the Security Policy apply for the
            purposes of Appendix 2 of the Processor Model Clauses. Developer
            hereby grants Creativ.eMail a mandate to execute the Processor Model
            Clauses, for and on behalf of Developer, with any relevant
            subcontractor (including affiliates) it appoints.
          </Policy.paragraph>
        </Policy.list>
        <Policy.subtitle>Schedule 2</Policy.subtitle>
        <Policy.subtitle>
          TERMS FOR INDEPENDENT PROCESSING FOR OWN PURPOSES
        </Policy.subtitle>
        <Policy.list>
          <Policy.titleListChild>Details of Processing</Policy.titleListChild>
          <Policy.paragraph>
            1.1 This Schedule 2 applies to the extent that each party
            independently determines the purpose and means of Processing of the
            Personal Data shared in connection with the API terms and
            conditions.
          </Policy.paragraph>
          <Policy.paragraph>
            1.2 The category of Data Subject is the Users and the categories of
            data shared includes and shall be limited to the following: (i)
            identification and contact information (such as name, email address,
            address, title and contact details) of Users’ customers and other
            contacts; (ii) information gathered in connection with enabling the
            Developer to provide Offerings to Users relating to Users’ contacts,
            including (a) analytics and information about marketing emails sent
            by Creativ.eMail on behalf of Users (including open rates and
            similar usage data) and (b) device, browser and information related
            to Users’ contacts identified through use of the Offerings. The
            Personal Data may contain special categories of Personal Data.
          </Policy.paragraph>
          <Policy.paragraph>
            1.3 The sharing of Personal Data is for the Purpose, and
            Creativ.eMail’s business purposes, including marketing.
          </Policy.paragraph>
          <Policy.paragraph>
            1.4 The recipients of the Personal Data shall be the Creativ.eMail
            and its affiliates, advisors and service providers.
          </Policy.paragraph>
          <Policy.titleListChild>Developer Obligations</Policy.titleListChild>
          <Policy.paragraph>
            2.1 The Developer shall only Process the Personal Data for the
            Purpose and in accordance with the terms of the API Terms and
            Conditions, including this Addendum.
          </Policy.paragraph>
          <Policy.paragraph>
            2.2 The Developer shall comply with Data Protection Laws, including
            implementing appropriate technical and organisational measures to
            ensure a level of security appropriate to the risk, taking into
            account the state of the art, the costs of implementation and the
            nature, scope, context and purpose of processing.
          </Policy.paragraph>
          <Policy.paragraph>
            2.3 The Developer shall promptly assist Creativ.eMail in complying
            with any:
          </Policy.paragraph>
          <Policy.indentParagraph>
            2.3.1  data subject rights request under the GDPR that Creativ.eMail
            may receive from any individuals to whom any Personal   Data shared
            by the Developer relates; and
          </Policy.indentParagraph>
          <Policy.indentParagraph>
            2.3.2  duties to cooperate with supervisory authorities under the
            GDPR.
          </Policy.indentParagraph>
          <Policy.paragraph>
            2.4 The Developer shall promptly report any Personal Data Breach of
            the Personal Data of the Users to Creativ.eMail.
          </Policy.paragraph>
          <Policy.paragraph>
            2.5 In addition to the requirements set out in clause 1.2 with
            respect to ensuring all Users agree to the User Agreements, the
            Developer agrees to do so in a manner that enables Creativ.eMail to
            Process the Personal Data shared with Creativ.eMail as contemplated
            by this Schedule 2 in accordance with Data Protection Laws,
            including by obtaining all necessary consents on behalf of
            Creativ.eMail and providing all necessary privacy policies,
            including as directed by Creativ.eMail.
          </Policy.paragraph>
          <Policy.titleListChild>
            Transfers outside the EEA
          </Policy.titleListChild>
          <Policy.paragraph>
            3.1 The Developer acknowledges and agrees that Creativ.eMail may
            Process the Personal Data in the United States in accordance with
            the Constant Contact, Inc.’s Privacy Shield certification which can
            be accessed 
            <Policy.inlineLink
              target="_blank"
              href="https://www.privacyshield.gov/list"
            >
              here
            </Policy.inlineLink>
            . In the event the Privacy Shield is invalidated, where
            Creativ.eMail processes, accesses, and/or stores Personal Data in
            any Third Country and the Developer transfers Personal Data from the
            European Economic Area, Creativ.eMail shall comply with the data
            importer’s obligations set out in the Controller Model Clauses,
            which are hereby incorporated into and form part of this Addendum
            and Developer shall comply with the data exporter’s obligations. For
            the purposes of the Controller Model Clauses, the parties agree that
            option (iii) in clause 2(h) of the Controller Model Clauses has been
            chosen and the information required by Annex B of the Controller
            Model Clauses is set out in this Addendum.
          </Policy.paragraph>
        </Policy.list>
      </Policy.content>
    </Policy>
  )
}

export default DataAddendum
