import styled from 'styled-components'
import { theme, media } from '../../helpers/theme'
import Link from 'gatsby-link'

const Policy = styled.div`
  color: ${theme.colors.font.lightGray};
  position: relative;
  overflow: hidden;
  padding: ${theme.space[8]}px;
`

Policy.content = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.space[20]}px auto 0;
  padding: ${theme.space[5]}px;
  width: auto;
  ${media('lg')} {
    max-width: 1100px;
  }
`

Policy.title = styled.div`
  font-size: ${theme.font['3xl']};
  padding: ${theme.space[8]}px 0;
  color: black;
`

Policy.bold = styled.span`
  font-weight: 600;
  color: ${theme.colors.black};
`

Policy.list = styled.ol`
  list-style-type: ${(props) => (props.isAlpha ? 'lower-alpha' : 'decimal')};
  padding-left: ${theme.space[8]}px;
`

Policy.titleListChild = styled.li`
  font-size: ${theme.font['xl']};
  padding: ${theme.space[5]}px 0;
  font-weight: 600;
  color: black;
`

Policy.listChild = styled.li`
  font-size: ${theme.font['base']};
  padding-bottom: ${theme.space[2]}px;
`

Policy.paragraph = styled.div`
  font-size: ${theme.font['base']};
  padding: ${theme.space[3]}px 0;
`

Policy.indentParagraph = styled.div`
  font-size: ${theme.font['base']};
  margin-left: ${theme.space[8]}px;
  padding: ${theme.space[3]}px 0;
`

Policy.subtitle = styled.div`
  font-size: ${theme.font['xl']};
  padding-bottom: ${theme.space[5]}px;
  font-weight: 600;
  color: black;
  text-transform: uppercase;
`

Policy.inlineLink = styled(Link)`
  color: ${theme.colors.darkPurple};
  text-decoration: none;
  font-size: inherit;
  line-break: anywhere;
  font-style: inherit;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.accent};
  }
`

Policy.updatedText = styled.span`
  margin-top: ${theme.space[4]}px;
  font-size: ${theme.font['sm']};
`

export default Policy
