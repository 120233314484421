import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Footer from '../components/footer'
import DataAddendum from '../components/data-addendum'

const DataTerm = () => {
  return (
    <Layout isWhiteLogo isPurple>
      <SEO title='Data Processing Addendum' pageType='home' flowType='none' />
      <DataAddendum />
      <Footer />
    </Layout>
  )
}

export default DataTerm
